<template lang="pug">
  v-container#invmod(fluid='' tag='section')
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-dialog(v-model='dialogAddProviderPrice' width='500')
      v-card
        v-card-title.text-h5.grey.lighten-2
          | Agregar Precio por Proveedor
        v-card-text
          v-row
            v-col.py-0(cols="12" md="12")
              v-autocomplete(dense="" v-model='providerSelect' :items='providersLst' return-object='return-object' item-text='completeData' label='Proveedor Interno')
            v-col.py-0(cols="12" md="12")
              v-autocomplete(dense="" v-model='providerSelect' :items='providersLstExterno' return-object='return-object' item-text='completeData' label='Proveedor Externo')
            v-col(cols='12' md='12')
              v-text-field(@keypress="isNumber($event)" v-model='priceSelect' label='Precio' placeholder='Precio' type='text' maxlength="25")
            v-data-table.mx-2#tablaDatosCombo(:headers="headers" v-show="!firstLoad" :items="lstProviderPrice" item-key="uid" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Productos por página'\}")
              template(v-slot:item.quit="{ item }")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(text="" icon="" color='red' v-on="on" @click="quitPrice(item)")
                      v-icon mdi-delete
                  span.tooltips Quitar Producto
              v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                | Sin resultados
              v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                | Sin datos
            v-col(cols="12")
              v-btn.mr-0(color='primary' @click='addProviderPriceRegister' style="width: 100%;" :loading="loadingSaveRegister")
                | AGREGAR
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='red' text='' @click='dialogAddProviderPrice = false')
            | CANCELAR
          v-btn(color='primary' text='' @click='dialogAddProviderPrice = false')
            | CONTINUAR
    v-card(v-if="!userStatus")
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title Registro de clientes
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModal')")
            v-icon.primary.rounded mdi-close
      v-card-text
        div(style="widht: 100% text-align: center; padding-top: 100px;")
          v-alert(type='error')
            | En este momento su cuenta se encuentra inactiva. Debe ponerse en contacto con su proovedor para verificar este error, de lo contrario por favor active su plan para seguir ingresando nuevos productos, gracias.
        row
          v-col(cols='12' md="12").text-center
            //v-btn.ml-2(min-width='0' text='' style='' @click="openLink('https://www.facebook.com/dental.prime.391/')")
              v-icon(color="black") mdi-facebook
            v-btn.ml-2(min-width='0' text=''  style="" @click="openLink('https://www.instagram.com/j2rtech/')")
              v-icon(color="black") mdi-instagram
            v-btn.ml-2(min-width='0' text='' style="" @click="openLink('https://wa.me/+593984530998')")
              v-icon(color="black") mdi-whatsapp
    v-card(v-if="userStatus")
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title Inventario de Productos
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModal')")
            v-icon.primary.rounded mdi-close
      v-card-text
        v-form(ref='formInventory' v-model='validInventory' lazy-validation='' autocomplete="random-string")
          v-container.py-0
            v-row
              v-col(cols="12")
                v-alert(v-model="alertData" dismissible dense='' border='left' type='info')
                  | Puede ajustar el inventario generando transacciones de ingreso y salida de mercadería en la vista principal en el botón: {{" "}}
                  v-icon(small style="font-size: 30px;") mdi-transfer
                  | . {{ }} Si estás obligado a llevar contabilidad debes tener cuidado de modificar los datos del inventario sin una transacción de venta o compra.
                v-alert(v-model="alertData" dismissible dense='' border='left' type='warning')
                  | Recuerde actualizar el IVA al valor correspondiente a este producto.
              v-col(cols='12' md='12' v-if="profile ? profile.twoAccounts : false" style="padding-top: 17px;")
                v-select(style="margin-top: 0px; margin-bottom: 10px; font-size: 26px; height: 30px;" hide-details="" return-object v-model='selectAccount' :items="acoountsLst" label="Seleccione la cuenta de facturación" item-text='texto')
              v-col(cols="12" md="8")
                h4(style="padding-bottom: 20px; padding-top: 20px;") Utilidad: {{utility}}
                v-row
                  v-col(cols='12' md='12')
                    v-select(v-if='branchsProfile.length > 0' label='Sucursales' return-object='' :items='branchsProfile' item-text='name' v-model='product.branch')
                v-row
                  v-col(cols='12' md='3')
                    v-text-field(@keyup="product.codArticle = product.codArticle.toUpperCase()" v-model='product.codArticle' :rules="requeridos" label='Cod. Artículo' placeholder='Cod. Artículo' type='text' maxlength="25")
                  v-col(cols='12' md='3')
                    v-text-field(@keyup="product.barcode = product.barcode.toUpperCase()" v-model='product.barcode' label='Cod. Barras' placeholder='Cod. Barras' type='text' maxlength="25" autofocus)
                  v-col(cols='12' md='6')
                    v-text-field(@keyup="product.name = product.name.toUpperCase()" v-model='product.name' :rules="requeridos" label='Nombre' placeholder='Nombre' type='text' maxlength="150")
                  v-col(cols='12' md='9')
                    v-text-field(@keyup="product.description = product.description.toUpperCase()" v-model='product.description' :rules="requeridos" label='Descripción' placeholder='Descripción' type='text' maxlength="300")
                  v-col(cols='12' md='3')
                    v-select(@change="changeTypeProduct" return-object v-model='product.type' :items="typeProductArr" label="Tipo de producto" item-text='texto')
                  v-col(cols="12" md="12")
                    v-autocomplete(id="searchInec" @keypress="filterDataInec()" :value="searchInecCode" v-model='product.inecCode' :items='lstInecodes' return-object='return-object' item-text='description' label='Código INEC')
                  v-col(cols="12" md="3" style="textAlign: center; margin-top: 20px")
                    label(style="font-size: 25px; font-weight: bold;") {{product.inecCode ? product.inecCode.code : 'NO ASIGNADO'}}
              v-col(cools="12" md="4")
                h4(style="padding-bottom: 20px; padding-top: 20px;") IVA - Cantidad / Valor
                v-row
                  v-col(cols='12' md='6' v-if="product.type.codigo.toString() === '01'")
                    v-text-field(v-model='product.quantity' :rules="requeridos" label='Cantidad' placeholder='Cantidad' type='text' maxlength="6" :readonly='forEditProduct === true' @keypress="isNumber($event)")
                  v-col(cols='12' md='6' v-if="product.type.codigo.toString() === '01'")
                    v-select(@change="changeIva" return-object v-model='product.tarifaiva' :items="electronicbillingtables.tarifaiva" label="Impuesto IVA" item-text='texto')
                  v-col(cols='12' v-if="product.type.codigo.toString() === '02'")
                    v-select(@change="changeIva" return-object v-model='product.tarifaiva' :items="electronicbillingtables.tarifaiva" label="Impuesto IVA" item-text='texto')
                  v-col(cols='12' md='6')
                    v-text-field(@keyup="calculateVals2" v-model='product.activeValue' :rules="requeridos" :label='$store.state.profile.profileLoaded.productPrice ? "Val. sin IVA MINORISTA $" : "Val. sin IVA $"' :placeholder='$store.state.profile.profileLoaded.productPrice ? "Val. sin IVA MINORISTA $" : "Val. sin IVA $"' type='text' maxlength="8" @keypress="isNumber($event)")
                  v-col(cols='12' md='6')
                    v-text-field(@keyup="calculateVals1" v-model='product.activeWValue' :rules="requeridos" :label='$store.state.profile.profileLoaded.productPrice ? "Val. con IVA MINORISTA $" : "Val. con IVA $"' :placeholder='$store.state.profile.profileLoaded.productPrice ? "Val. con IVA MINORISTA $" : "Val. con IVA $"' type='text' maxlength="8" @keypress="isNumber($event)")
                  v-col(cols='12' md='6' v-if="$store.state.profile.profileLoaded.productPrice")
                    v-text-field(@keyup="calculateVals2" v-model='product.activeValueWholesaler' :rules="requeridos" label='VAL. sin IVA MAYORISTA $' placeholder='Val. sin IVA MAYORISTA $' type='text' maxlength="8" @keypress="isNumber($event)")
                  v-col(cols='12' md='6' v-if="$store.state.profile.profileLoaded.productPrice")
                    v-text-field(@keyup="calculateVals1" v-model='product.activeWValueWholesaler' :rules="requeridos" label='Val. con IVA MAYORISTA $' placeholder='Val. con IVA MAYORISTA $' type='text' maxlength="8" @keypress="isNumber($event)")
                  v-col(cols='12' md='6')
                    v-text-field(@keyup="calculateVals2" v-model='product.purchaseValue' label='VAL. sin IVA COMPRA $' placeholder='VAL. sin IVA COMPRA $' type='text' maxlength="8" @keypress="isNumber($event)")
                  v-col(cols='12' md='6')
                    v-text-field(@keyup="calculateVals1" v-model='product.purchaseWValue' label='Val. con IVA COMPRA $' placeholder='Val. con IVA COMPRA $' type='text' maxlength="8" @keypress="isNumber($event)")
                  v-col(cols="12")
                    v-btn.mr-0(color='primary' @click='saveProduct' style="width: 100%;" :loading="loadingSave")
                      | GUARDAR
                  v-col(cols="12" v-if="profileLoaded ? profileLoaded.cartotradesa : false")
                    v-btn.mr-0(color='secondary' @click='addProviderPrice' style="width: 100%;")
                      | AGREGAR PRECIO POR PROVEEDOR
            //h2(style="padding-bottom: 20px; padding-top: 20px;") Impuestos
            //v-row
              v-col(cols='12' md='4')
                v-select(return-object v-model='product.tarifaiva' :items="electronicbillingtables.tarifaiva" label="Impuesto IVA" item-text='texto')
              //v-col(cols='12' md='4')
                v-select(return-object v-model='product.irbpnr' :items="electronicbillingtables.irbpnr" label="Impuesto IRBPNR" item-text='texto')
              //v-col(cols='12' md='4')
                v-autocomplete(return-object v-model='product.tarifaice' :items="electronicbillingtables.tarifaice" label="Impuesto ICE" item-text='descripcion')
            v-row
              v-col(cols='12' md="6" offset-md="3" v-if="!$store.state.profile.profileLoaded.productPrice")
                p(style='margin-top: 25px; text-align: center; font-size: 25px;')
                  | Valor sin IVA: $ {{(parseFloat(parseFloat(this.product.quantity) * parseFloat(this.product.activeValue)).toFixed(3)) === 'NaN' ? '0.00': (parseFloat(parseFloat(this.product.quantity) * parseFloat(this.product.activeValue)).toFixed(3))}}
              v-col(cols='12' md="6" v-if="$store.state.profile.profileLoaded.productPrice")
                p(style='margin-top: 25px; text-align: center; font-size: 25px;')
                  | Valor sin IVA MINORISTA: $ {{(parseFloat(parseFloat(this.product.quantity) * parseFloat(this.product.activeValue)).toFixed(3)) === 'NaN' ? '0.00': (parseFloat(parseFloat(this.product.quantity) * parseFloat(this.product.activeValue)).toFixed(3))}}
              v-col(cols='12' md="6" v-if="$store.state.profile.profileLoaded.productPrice")
                p(style='margin-top: 25px; text-align: center; font-size: 25px;')
                  | Valor sin IVA MAYORISTA: $ {{(parseFloat(parseFloat(this.product.quantity) * parseFloat(this.product.activeValueWholesaler)).toFixed(3)) === 'NaN' ? '0.00': (parseFloat(parseFloat(this.product.quantity) * parseFloat(this.product.activeValueWholesaler)).toFixed(3))}}
</template>

<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import 'firebase/auth'
  import * as functions from '../../../../functions'
  export default {
    props: {
      forEditProduct: {
        default: false,
        type: Boolean,
      },
      productToEdit: {
        default: function () {
          return {
            codArticle: '',
            barcode: '',
            name: '',
            description: '',
            type: {
              codigo: '01',
              texto: 'BIEN',
            },
            quantity: '1',
            activeValue: '',
            activeWValue: '',
            activeValueWholesaler: '',
            activeWValueWholesaler: '',
            purchaseValue: '',
            purchaseWValue: '',
            productKey: '',
            completeData: '',
            tarifaiva: {
              codigo: '2',
              texto: '12%',
              valor: '12',
            },
            irbpnr: '',
            tarifaice: '',
            totalValue: '',
            inecCode: null,
            lstProviderPrice: [],
            branch: null,
          }
        },
        type: Object,
      },
    },
    data: () => ({
      utility: '0.00',
      loadingSaveRegister: false,
      headers: [
        {
          text: 'Proveedor',
          value: 'provider.completeData',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Precio',
          value: 'price',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Quitar',
          align: 'center',
          value: 'quit',
          sortable: false,
        },
      ],
      ordenarPor: 'codArticle',
      cargando: false,
      firstLoad: false,
      dialogAddProviderPrice: false,
      acoountsLst: [
        {
          code: '001',
          texto: 'Ingresar datos de la Cuenta 1.',
        },
        {
          code: '002',
          texto: 'Ingresar datos de la Cuenta 2.',
        },
      ],
      selectAccount: {
        code: '001',
        texto: 'Ingresar datos de la Cuenta 1.',
      },
      searchInecCode: '',
      timeout: null,
      alertData: true,
      typeProductArr: [
        {
          codigo: '01',
          texto: 'BIEN',
        },
        {
          codigo: '02',
          texto: 'SERVICIO',
        },
      ],
      providerLstFilter: [],
      product: {
        codArticle: '',
        barcode: '',
        name: '',
        description: '',
        type: {
          codigo: '01',
          texto: 'BIEN',
        },
        quantity: '1',
        activeValue: '',
        activeWValue: '',
        activeValueWholesaler: '',
        activeWValueWholesaler: '',
        purchaseValue: '',
        purchaseWValue: '',
        productKey: '',
        completeData: '',
        tarifaiva: {
          codigo: '2',
          texto: '12%',
          valor: '12',
        },
        irbpnr: '',
        tarifaice: '',
        totalValue: '',
        inecCode: null,
        lstProviderPrice: [],
        branch: null,
      },
      lstProviderPrice: [],
      providerSelect: null,
      priceSelect: '',
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      loadingSave: false,
      validInventory: true,
      requeridos: [
        value => !!value || 'Requerido',
      ],
      userStatus: true,
      profile: null,
    }),
    computed: {
      branchsSelected () {
        return this.$store.state.profile.branchSelected
      },
      branchsProfile () {
        return this.$store.state.profile.branchsProfile
      },
      providersLst () {
        return this.$store.state.provider.lstProviders
      },
      providersLstExterno () {
        return this.$store.state.provider.lstProvidersExternos
      },
      lstInecodes () {
        return this.$store.state.facturacion.lstInecCodes
      },
      providerLst () {
        return this.$store.state.provider.lstProviders
      },
      planLst () {
        return this.$store.state.suscriptionPlan.plan
      },
      productLst () {
        return this.$store.state.product.lstProductsActive
      },
      electronicbillingtables () {
        return this.$store.state.facturacion.electronicbillingtables
      },
      profileLoaded () {
        return this.$store.state.profile.profileLoaded
      },
    },
    watch: {
      profileLoaded () {
        if (this.profileLoaded) this.profile = this.profileLoaded
        if (this.profile) {
          if (this.profile.rucEstablecimieto) {
            if (this.selectAccount.code !== '002') {
              this.selectAccount = {
                code: '001',
                texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
              }
            }
            this.acoountsLst = [
              {
                code: '001',
                texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
              },
              {
                code: '002',
                texto: 'Ingresar datos de la Cuenta 2.',
              },
            ]
            if (this.profile.rucEstablecimieto2) {
              this.acoountsLst = [
                {
                  code: '001',
                  texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto : 'Ingresar datos de la Cuenta 1.',
                },
                {
                  code: '002',
                  texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 2.',
                },
              ]
            }
          }
        }
      },
      planLst () {
        if (this.productLst.length >= this.planLst.productos) {
          this.userStatus = false
        } else {
          this.userStatus = true
        }
      },
      productToEdit () {
        this.product = this.productToEdit
        if (!this.product.branch) {
          this.product.branch = this.branchsSelected
        }
        if (this.product.lstProviderPrice) {
          this.lstProviderPrice = this.product.lstProviderPrice
        }
        if (!this.product.activeWValue) {
          this.product.activeWValue = '0.00'
        }
        if (!this.product.activeValue) {
          this.product.activeValue = '0.00'
        }
        if (this.$store.state.profile.profileLoaded.productPrice) {
          if (!this.product.activeWValueWholesaler) {
            this.product.activeWValueWholesaler = '0.00'
          }
          if (!this.product.activeValueWholesaler) {
            this.product.activeWValueWholesaler = '0.00'
          }
        }
        this.alertData = true
        this.snackbar = {
          show: true,
          color: 'orange',
          text: 'La descripción del producto debe tener un máximo de 300 caracteres y el nombre de 150.',
        }
        this.calculateVals2()
        if (this.product.accountType) {
          if (this.product.accountType === '001') {
            this.selectAccount = {
              code: '001',
              texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
            }
          } else {
            this.selectAccount = {
              code: '002',
              texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 1.',
            }
          }
        } else {
          this.selectAccount = {
            code: '001',
            texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
          }
        }
        if (this.product.activeValue !== '' && this.product.purchaseValue !== '' && this.product.activeValue && this.product.purchaseValue) {
          const valUtility = (parseFloat(this.product.activeValue - this.product.purchaseValue) * parseFloat(this.product.quantity)).toFixed(3)
          this.utility = '$' + valUtility
        } else {
          this.utility = 'Verificar el valor de compra y venta.'
        }
      },
      // proceso para filtrado y seleccion de proveedores
      providerLst () {
        this.providerLstFilter = this.providerLst
      },
    },
    mounted () {
      this.alertData = true
      this.snackbar = {
        show: true,
        color: 'orange',
        text: 'La descripción del producto debe tener un máximo de 300 caracteres y el nombre de 150.',
      }
      this.electronicbillingtables = this.$store.state.facturacion.electronicbillingtables
      // para no usar la variable de props porque da error al modificar una variable prop
      this.product = this.productToEdit
      if (!this.product.branch) {
        this.product.branch = this.branchsSelected
      }
      if (this.product.lstProviderPrice) {
        this.lstProviderPrice = this.product.lstProviderPrice
      }
      // se cargan todo los proveedores en providerLstFilter
      this.providerLstFilter = this.providerLst
      if (this.$store.state.product.lstProducts.length >= this.$store.state.suscriptionPlan.plan.productos) {
        this.userStatus = false
      } else {
        this.userStatus = true
      }
      this.profile = this.$store.state.profile.profileLoaded
      if (this.profile) {
        if (this.profile.rucEstablecimieto) {
          this.selectAccount = {
            code: '001',
            texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
          }
          this.acoountsLst = [
            {
              code: '001',
              texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
            },
            {
              code: '002',
              texto: 'Ingresar datos de la Cuenta 2.',
            },
          ]
          if (this.profile.rucEstablecimieto2) {
            this.acoountsLst = [
              {
                code: '001',
                texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
              },
              {
                code: '002',
                texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 2.',
              },
            ]
          }
        }
        if (this.product.accountType) {
          if (this.product.accountType === '001') {
            this.selectAccount = {
              code: '001',
              texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
            }
          } else {
            this.selectAccount = {
              code: '002',
              texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 1.',
            }
          }
        } else {
          this.selectAccount = {
            code: '001',
            texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
          }
        }
      }
      if (this.product.activeValue !== '' && this.product.purchaseValue !== '' && this.product.activeValue && this.product.purchaseValue) {
        const valUtility = (parseFloat(this.product.activeValue - this.product.purchaseValue) * parseFloat(this.product.quantity)).toFixed(3)
        this.utility = '$' + valUtility
      } else {
        this.utility = 'Verificar el valor de compra y venta.'
      }
    },
    methods: {
      addProviderPriceRegister () {
        if (!this.providerSelect) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Para continuar debe seleccionar el proveedor.',
          }
          return
        }
        if (this.priceSelect === '') {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Para continuar debe agregar el precio para el proveedor.',
          }
          return
        }

        if (!this.lstProviderPrice) {
          this.lstProviderPrice = []
        }

        const providerLst = this.lstProviderPrice.filter(item => {
          return item.provider.id === this.providerSelect.id
        })

        if (providerLst.length > 0) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'El proveedor seleccionado ya tiene agregado un precio.',
          }
          return
        }

        this.lstProviderPrice.push({
          provider: this.providerSelect,
          price: this.priceSelect,
        })
        this.providerSelect = null
        this.priceSelect = ''
      },
      quitPrice (item) {
        for (let i = 0; i < this.lstProviderPrice.length; i++) {
          if (item.provider.id === this.lstProviderPrice[i].provider.id) {
            this.lstProviderPrice.splice(i, 1)
          }
        }
      },
      addProviderPrice () {
        this.dialogAddProviderPrice = true
      },
      async getLstInec () {
        this.searchInecCode = document.getElementById('searchInec').value
        this.searchInecCode = functions.specialCharacters(this.searchInecCode).toUpperCase()
        if (!this.$store.state.facturacion.tokenAdmin) {
          await this.$store.dispatch('facturacion/adminLoginFac')
        }
        const data = {
          token: this.$store.state.facturacion.tokenAdmin,
          filterData: this.searchInecCode,
        }
        this.$store.dispatch('facturacion/getInecCodes', data)
      },
      filterDataInec () {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.getLstInec()
        }, 1000)
      },
      changeIva () {
        let banContinue = true
        if (this.product.activeWValue.toString() !== '' || this.product.activeValue.toString() !== '') {
          if (this.product.activeValue.toString() !== '') {
            const valIva = 1 + parseFloat(this.product.tarifaiva.valor) / 100
            if (parseFloat(valIva) > 0) {
              this.product.activeWValue = (parseFloat(this.product.activeValue) * parseFloat(valIva)).toFixed(3)
            } else {
              this.product.activeWValue = this.product.activeValue
            }
            banContinue = false
          }
          if (banContinue) {
            if (this.product.activeWValue.toString() !== '') {
              const valIva = 1 + parseFloat(this.product.tarifaiva.valor) / 100
              if (parseFloat(valIva) > 0) {
                this.product.activeValue = (parseFloat(this.product.activeWValue) / parseFloat(valIva)).toFixed(3)
              } else {
                this.product.activeValue = this.product.activeWValue
              }
            }
          }
        }
        let banContinueW = true
        if (this.$store.state.profile.profileLoaded.productPrice) {
          if (this.product.activeWValueWholesaler.toString() !== '' || this.product.activeValueWholesaler.toString() !== '') {
            if (this.product.activeValueWholesaler.toString() !== '') {
              const valIva = 1 + parseFloat(this.product.tarifaiva.valor) / 100
              if (parseFloat(valIva) > 0) {
                this.product.activeWValueWholesaler = (parseFloat(this.product.activeValueWholesaler) * parseFloat(valIva)).toFixed(3)
              } else {
                this.product.activeWValueWholesaler = this.product.activeValueWholesaler
              }
              banContinueW = false
            }
            if (banContinueW) {
              if (this.product.activeWValueWholesaler.toString() !== '') {
                const valIva = 1 + parseFloat(this.product.tarifaiva.valor) / 100
                if (parseFloat(valIva) > 0) {
                  this.product.activeValueWholesaler = (parseFloat(this.product.activeWValueWholesaler) / parseFloat(valIva)).toFixed(3)
                } else {
                  this.product.activeValueWholesaler = this.product.activeWValueWholesaler
                }
              }
            }
          }
        }
      },
      calculateVals1 () {
        if (this.product.activeWValue) {
          if (this.product.activeWValue.toString() !== '') {
            const valIva = 1 + parseFloat(this.product.tarifaiva.valor) / 100
            if (parseFloat(valIva) > 0) {
              this.product.activeValue = (parseFloat(this.product.activeWValue) / parseFloat(valIva)).toFixed(3)
              if (this.product.purchaseWValue) this.product.purchaseValue = this.product.purchaseWValue.toString() !== '' ? (parseFloat(this.product.purchaseWValue) / parseFloat(valIva)).toFixed(3) : ''
            } else {
              this.product.activeValue = this.product.activeWValue
              this.product.purchaseValue = this.product.purchaseWValue
            }
          } else {
            this.product.activeValue = ''
          }
        } else {
          this.product.activeValue = ''
        }
        if (this.$store.state.profile.profileLoaded.productPrice) {
          if (this.product.activeWValueWholesaler) {
            if (this.product.activeWValueWholesaler.toString() !== '') {
              const valIva = 1 + parseFloat(this.product.tarifaiva.valor) / 100
              if (parseFloat(valIva) > 0) {
                this.product.activeValueWholesaler = (parseFloat(this.product.activeWValueWholesaler) / parseFloat(valIva)).toFixed(3)
              } else {
                this.product.activeValueWholesaler = this.product.activeWValueWholesaler
              }
            } else {
              this.product.activeValueWholesaler = ''
            }
          } else {
            this.product.activeValueWholesaler = ''
          }
        }
        if (this.product.activeValue !== '' && this.product.purchaseValue !== '' && this.product.activeValue && this.product.purchaseValue) {
          const valUtility = (parseFloat(this.product.activeValue - this.product.purchaseValue) * parseFloat(this.product.quantity)).toFixed(3)
          this.utility = '$' + valUtility
        } else {
          this.utility = 'Verificar el valor de compra y venta.'
        }
      },
      calculateVals2 () {
        if (this.product.activeValue) {
          if (this.product.activeValue.toString() !== '') {
            const valIva = 1 + parseFloat(this.product.tarifaiva.valor) / 100
            if (parseFloat(valIva) > 0) {
              this.product.activeWValue = (parseFloat(this.product.activeValue) * parseFloat(valIva)).toFixed(3)
              if (this.product.purchaseValue) this.product.purchaseWValue = this.product.purchaseValue.toString() !== '' ? (parseFloat(this.product.purchaseValue) * parseFloat(valIva)).toFixed(3) : ''
            } else {
              this.product.activeWValue = this.product.activeValue
              this.product.purchaseWValue = this.product.purchaseValue
            }
          } else {
            this.product.activeWValue = ''
          }
        } else {
          this.product.activeWValue = ''
        }
        if (this.$store.state.profile.profileLoaded.productPrice) {
          if (this.product.activeValueWholesaler) {
            if (this.product.activeValueWholesaler.toString() !== '') {
              const valIva = 1 + parseFloat(this.product.tarifaiva.valor) / 100
              if (parseFloat(valIva) > 0) {
                this.product.activeWValueWholesaler = (parseFloat(this.product.activeValueWholesaler) * parseFloat(valIva)).toFixed(3)
              } else {
                this.product.activeWValueWholesaler = this.product.activeValueWholesaler
              }
            } else {
              this.product.activeWValueWholesaler = ''
            }
          } else {
            this.product.activeWValueWholesaler = ''
          }
        }
        this.snackbar = {
          show: true,
          color: 'green',
          text: 'Valor procesado correctamente.',
        }
        if (this.product.activeValue !== '' && this.product.purchaseValue !== '' && this.product.activeValue && this.product.purchaseValue) {
          const valUtility = (parseFloat(this.product.activeValue - this.product.purchaseValue) * parseFloat(this.product.quantity)).toFixed(3)
          this.utility = '$' + valUtility
        } else {
          this.utility = 'Verificar el valor de compra y venta.'
        }
      },
      changeTypeProduct () {
        if (this.product.type.codigo.toString() === '02') {
          this.product.quantity = 1
        }
      },
      isNumber: function (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      async saveProduct () {
        if (this.$refs.formInventory.validate()) {
          if (parseFloat(this.product.cantidad) <= 0) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'La cantidad no puede ser menor de 1.',
            }
            return
          }
          const productCodeFilt = this.productLst.filter(product => product.codArticle === this.product.codArticle)
          if (this.forEditProduct === true) {
            if (productCodeFilt.length > 1) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Ya existe un producto registrado con ese código.',
              }
              return
            }
          } else {
            if (productCodeFilt.length > 0) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Ya existe un producto registrado con ese código.',
              }
              return
            }
          }
          if (parseFloat(this.product.activeValue) <= 0) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El precio del producto no puede ser 0.',
            }
            return
          }
          if (this.product.productKey === '') {
            this.product.productKey = firebase.database().ref(`products/${this.$store.state.user.user.uid}`).push().getKey()
          }
          this.product.activeValue = parseFloat(this.product.activeValue.toString().replace(',', '.'))
          this.product.totalValue = parseFloat(parseFloat(this.product.quantity) * parseFloat(this.product.activeValue)).toFixed(3)
          this.product.completeData = this.product.codArticle + ' | ' + this.product.name + ' | ' + this.product.description + ' | ' + this.product.barcode
          this.product.accountType = this.selectAccount.code
          this.product.validChangeIva = true
          if (this.lstProviderPrice.length > 0) {
            this.product.lstProviderPrice = this.lstProviderPrice
          }
          await this.$store.dispatch('product/setProduct', [this.product.productKey, this.product]).then(async resolve => {
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Producto guardado correctamente',
            }
            this.$emit('closeModal')
          }, reject => {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al guardar el producto',
            }
          })
        }
      },
    },
  }
</script>
